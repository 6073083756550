import React from 'react';
import { media } from 'theme/breakpoints';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const StyledParagraph = styled.p`
  font-size: ${({ size }) => size[0]}px;
  line-height: ${({ size }) => size[1]}px;
  font-weight: 400;
  margin: 0;
  ${media.mobile} {
    font-size: 18px;
    line-height: 28px;
  }
`;

const Paragraph = ({ size, children, style, className }) => {
  const renderSize = (sizes) => {
    switch (sizes) {
      case 's':
        return [14, 24];
      case 'l':
        return [18, 30];
      case 'xl':
        return [22, 34];
      default:
        return [16, 24];
    }
  };

  return (
    <StyledParagraph size={renderSize(size)} style={style} className={className}>
      {children}
    </StyledParagraph>
  );
};

export default Paragraph;

Paragraph.propTypes = {
  size: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object, PropTypes.node]).isRequired,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.any]),
  className: PropTypes.string,
};

Paragraph.defaultProps = {
  size: 'm',
  style: undefined,
  className: '',
};
