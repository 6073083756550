import styled from 'styled-components';
import { media } from 'theme/breakpoints';

export const Trusted = styled.section`
  display: flex;
  flex-direction: column;
  max-width: 1200px;
  margin: 0 auto;
  ${media.tablet} {
    padding: ${(props) => (props.intercomPage ? '0 0 40px' : '0 15px')};
    max-width: 576px;
  }
  ${media.mobile} {
    padding: ${(props) => (props.intercomPage ? '0 0 40px' : '30px 0 0')};
  }
  p {
    font-size: 16px;
    line-height: 30px;
    font-weight: ${(props) => (props.hero ? 600 : 700)};
    margin: 0 auto;
    text-transform: ${(props) => (props.hero ? 'none' : 'uppercase')};
    color: #6a6a6a;
    ${media.mobile} {
      font-size: 18px;
    }
    &.custom-text {
      font-weight: 400;
      text-transform: none;
      font-size: 18px;
      & b {
        font-weight: 800;
      }
      ${media.tablet} {
        text-align: center;
      }
    }
  }
`;
export const Icons = styled.div`
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-row-gap: 22px;
  grid-column-gap: 32px;
  align-items: center;
  justify-content: space-between;
  grid-template-rows: ${(props) => (props.hero ? 'min-content' : 'repeat(2,min-content)')};
  & .gatsby-image-wrapper {
    img {
      left: 50% !important;
      transform: translateX(-50%);
    }
  }
  img {
    margin: 0 auto;
    max-width: 140px;
  }
  ${media.desktopWide} {
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
    & .last-in-row,
    & .last-in-second-row {
      display: none;
    }

    & img {
      max-width: 120px;
    }
  }
  ${media.desktop} {
    margin-top: 40px;
    & img {
      max-width: 80px;
    }
  }
  ${media.tablet} {
    justify-content: center;
    grid-template-rows: ${(props) => (props.hero ? 'repeat(2, 57px)' : 'repeat(4, 57px)')};
    grid-template-columns: repeat(3, 100px);
    grid-column-gap: 50px;
    grid-row-gap: 10px;
    & img {
      max-width: 110px;
    }
  }
  ${media.mobile} {
    grid-column-gap: 15px;
    grid-row-gap: 8px;
    & img {
      max-width: 100px;
    }
  }
`;
