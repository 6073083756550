import { useStaticQuery, graphql } from 'gatsby';

export const useLogoCompanies = () => {
  const {
    rocketlink,
    databox,
    kissflow,
    livereacting,
    logicalplan,
    trail,
    midigator,
    hypestHive,
    corra,
    vitreo,
    scalablePress,
    spySerp,
    talentLyft,
    currenxie,
    renmoney,
  } = useStaticQuery(graphql`
    query {
      rocketlink: file(relativePath: { eq: "companyLogo/rocketlink.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 340) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      kissflow: file(relativePath: { eq: "companyLogo/kissflow.svg" }) {
        publicURL
      }
      livereacting: file(relativePath: { eq: "companyLogo/livereacting.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 452) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      logicalplan: file(relativePath: { eq: "companyLogo/logicalplan.svg" }) {
        publicURL
      }
      trail: file(relativePath: { eq: "companyLogo/trail.svg" }) {
        publicURL
      }
      hypestHive: file(relativePath: { eq: "companyLogo/hypestHive.svg" }) {
        publicURL
      }
      corra: file(relativePath: { eq: "companyLogo/corra.svg" }) {
        publicURL
      }
      vitreo: file(relativePath: { eq: "companyLogo/vitreo.svg" }) {
        publicURL
      }
      scalablePress: file(relativePath: { eq: "companyLogo/scalablePress.svg" }) {
        publicURL
      }
      spySerp: file(relativePath: { eq: "companyLogo/spyserp.png" }) {
        childImageSharp {
          fluid(quality: 100, maxWidth: 253) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      midigator: file(relativePath: { eq: "companyLogo/midigator.svg" }) {
        publicURL
      }
      talentLyft: file(relativePath: { eq: "companyLogo/talentLyft.svg" }) {
        publicURL
      }
      currenxie: file(relativePath: { eq: "companyLogo/currenxie.svg" }) {
        publicURL
      }
      renmoney: file(relativePath: { eq: "companyLogo/renmoney.svg" }) {
        publicURL
      }
      databox: file(relativePath: { eq: "companyLogo/databox.svg" }) {
        publicURL
      }
    }
  `);

  return {
    rocketlink,
    databox,
    kissflow,
    livereacting,
    logicalplan,
    trail,
    midigator,
    hypestHive,
    corra,
    vitreo,
    scalablePress,
    spySerp,
    talentLyft,
    currenxie,
    renmoney,
  };
};
