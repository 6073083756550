import React from 'react';
import PropTypes from 'prop-types';
import Img from 'gatsby-image/withIEPolyfill';
import { useLogoCompanies } from 'hooks/useLogoCompanies';
import * as Styled from './styled';

const TrustedBy = ({ deleteText, style, hero, customText, intercomPage }) => {
  const {
    databox,
    kissflow,
    rocketlink,
    logicalplan,
    trail,
    hypestHive,
    corra,
    vitreo,
    scalablePress,
    spySerp,
    midigator,
    talentLyft,
    currenxie,
    renmoney,
  } = useLogoCompanies();

  return (
    <Styled.Trusted style={style} hero={hero} intercomPage={intercomPage}>
      {!deleteText && customText ? (
        <p className="custom-text">{customText}</p>
      ) : (
        <p>Trusted by 1000+ teams worldwide</p>
      )}
      <Styled.Icons hero={hero}>
        <img src={kissflow.publicURL} alt="kissflow" title="Kissflow" />
        <img src={databox.publicURL} alt="databox" title="Databox" />
        <img src={talentLyft.publicURL} alt="talentlyft" title="TalentLyft" />
        <img src={currenxie.publicURL} alt="currenxie" title="Currenxie" />
        <img src={renmoney.publicURL} alt="renmoney" title="Renmoney" />
        <img src={corra.publicURL} alt="corra" title="Corra" />
        <img src={trail.publicURL} alt="trail" title="Trail" className="last-in-row" />
        {hero ? null : (
          <>
            <img src={hypestHive.publicURL} alt="hypestHive" title="Hypest Hive" />
            <img src={logicalplan.publicURL} alt="logicalplan" title="LogicalPlan" />
            <img src={vitreo.publicURL} alt="vitreo" title="Vitreo" />
            <Img
              fluid={spySerp.childImageSharp.fluid}
              alt="spyserp"
              fadeIn
              title="Spyserp"
              objectFit="contain"
            />
            <img src={scalablePress.publicURL} alt="scalablePress" title="Scalable Press" />
            <img src={midigator.publicURL} alt="midigator" title="Midigator" />
            <Img
              fluid={rocketlink.childImageSharp.fluid}
              alt="rocketlink"
              fadeIn
              title="Rocketlink"
              objectFit="contain"
              className="last-in-second-row"
            />
          </>
        )}
      </Styled.Icons>
    </Styled.Trusted>
  );
};

export default TrustedBy;

TrustedBy.propTypes = {
  deleteText: PropTypes.bool,
  intercomPage: PropTypes.bool,
  hero: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  customText: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
};

TrustedBy.defaultProps = {
  deleteText: false,
  intercomPage: false,
  hero: false,
  style: null,
  customText: null,
};
